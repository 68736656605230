import React, { useRef, useState, useEffect } from "react"
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Grid,
  Hidden,
} from "@material-ui/core"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import PermIdentityIcon from "@material-ui/icons/PermIdentity"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useSelector } from "react-redux"
import Layout from "../components/Layout"
import ReactMapGL, { Source, Layer } from "react-map-gl"
import MAP_STYLE from "../mapStyles/style.json"
import DateFnsUtils from "@date-io/date-fns"
import LocalizedLink from "../components/LocalizedLink"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import { SEO } from "../components/SEO"

const mapTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#FF9486",
      contrastText: "#010204",
      dark: "#ff5252",
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#FF9486",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#FF9486",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "MabryProRegular",
      },
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: 0,
        },
      },
    },
  },
  props: {
    MuiFormControl: {
      margin: "normal",
    },
  },
})

const useStyles = makeStyles(theme => ({
  cardStyle: {
    margin: theme.spacing(2),
    height: "95vh",
  },
  logo: {
    fontFamily: "MabryProBold",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: 0,
    lineHeight: "24px",
  },
  pgNumStyle: {
    fontSize: "2rem",
    lineHeight: "32px",
  },
  inputLabel: {
    position: "unset",
    transform: "unset",
  },
  peopleInputLabel: {
    position: "unset",
    transform: "unset",
    textAlign: "center",
  },
  centerText: {
    textAlign: "center",
  },
  changeLangLabel: {
    fontSize: "0.875rem",
    fontFamily: "MabryProRegular",
  },
  destinationNo: {
    border: "rgba(1, 2, 4, 0.1) 1px solid",
    borderRadius: "2px",
    fontFamily: "MabryProRegular",
    fontSize: "0.75rem",
    lineHeight: "16px",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  destinationCircle: {
    height: "8px",
    width: "8px",
    border: "#010204 1px solid",
    borderRadius: "50%",
  },
  destinationDot: {
    height: "4px",
    width: "4px",
    backgroundColor: "rgba(1, 2, 4, 0.1)",
    borderRadius: "50%",
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#ddd",
      color: "#000",
    },
    transition: "all 0.3s ease",
  },
}))

const MapScreen = ({ pageContext, location }) => {
  const classes = useStyles()
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.mapScreen
  )

  const locale = useSelector(state =>
    state?.locale?.localeObj.locale.substring(0, 2)
  )

  const bounds = [
    [-9.527214680153534, 35.99714681953937], // [west, south]
    [3.32553728233662, 43.79118702714307], // [east, north]
  ]

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100vh",
    longitude: -2.972941798187875,
    latitude: 40.231692638212685,
    zoom: 5.671041686566337,
  })

  const [mobileViewport, setMobileViewport] = useState({
    width: "100%",
    height: "43vh",
    maxHeight: "43vh",
    longitude: -2.972941798187875,
    latitude: 40.231692638212685,
    zoom: 4.3,
  })

  const _onViewportChange = viewport => {
    const { longitude, latitude, zoom } = viewport
    viewport.longitude = Math.max(
      Math.min(longitude, bounds[1][0]),
      bounds[0][0]
    )
    viewport.latitude = Math.max(Math.min(latitude, bounds[1][1]), bounds[0][1])
    viewport.zoom = Math.max(Math.min(zoom, 12), 5)

    setViewport(viewport)
  }

  const _onMobileViewportChange = mobileViewport => {
    const { longitude, latitude, zoom } = mobileViewport
    mobileViewport.longitude = Math.max(
      Math.min(longitude, bounds[1][0]),
      bounds[0][0]
    )
    mobileViewport.latitude = Math.max(
      Math.min(latitude, bounds[1][1]),
      bounds[0][1]
    )
    mobileViewport.zoom = Math.max(Math.min(zoom, 12), 4)

    setMobileViewport(mobileViewport)
  }

  const [route, setRoute] = useState([])

  const itineraryData = location?.state?.itineraryData
    ? location.state.itineraryData
    : null
  const reqsData = location?.state?.reqsData ? location.state.reqsData : null
  const mapRef = useRef()
  const [cities, setCities] = useState(
    itineraryData ? itineraryData.cities : [{ place_name: "" }]
  )

  // chinese not fully supported yet

  // useEffect(() => {
  //   mapRef.current.getStyle().layers.forEach((layer) => {
  //     if (layer.layout && layer.layout['text-field']) {
  //       mapRef.current.setLayoutProperty(layer.id, 'text-field', [
  //         'coalesce',
  //         ['get', 'name_zh'],
  //         ['get', 'name'],
  //       ]);
  //     }
  //   });
  // }, [mapRef])

  useEffect(() => {
    updateRoutes(cities)
  }, [cities])

  const [startDate, setStartDate] = useState(
    itineraryData ? itineraryData.startDate : new Date()
  )
  const [endDate, setEndDate] = useState(
    itineraryData ? itineraryData.endDate : new Date()
  )

  const [adults, setAdults] = useState(itineraryData ? itineraryData.adults : 0)
  const [children, setChildren] = useState(
    itineraryData ? itineraryData.childrenNum : 0
  )
  const [infants, setInfants] = useState(
    itineraryData ? itineraryData.infants : 0
  )

  const [budget, setBudget] = useState(
    itineraryData ? itineraryData.budget : ""
  )

  const noOfPeopleMenu = [
    { label: "Adults (13+ years)", value: adults, setter: setAdults },
    {
      label: "Child (4-12 years)",
      value: children,
      setter: setChildren,
    },
    {
      label: "Infant (under 3 years)",
      value: infants,
      setter: setInfants,
    },
  ]

  const [peopleMenuOpen, setPeopleMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePeopleClick = event => {
    setPeopleMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPeopleMenu(false)
    setAdults(adults)
    setChildren(children)
    setInfants(infants)
  }

  const handleModifyPeople = (newValue, setFunc) => {
    setFunc(newValue)
  }

  const tourLangsEn = ["English", "Chinese"]
  const tourLangsZh = ["英语", "中文"]

  const [tourLangs, setTourLangs] = useState([])

  useEffect(() => {
    if (locale) {
      if (locale === "en") {
        setTourLangs(tourLangsEn)
        setSelectedLang(tourLangsEn[0])
      } else if (locale === "zh") {
        setTourLangs(tourLangsZh)
        setSelectedLang(tourLangsZh[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const [selectedLang, setSelectedLang] = useState(tourLangs[0])

  const ChangeLangLabel = () => (
    <div>
      <Typography noWrap color="secondary" className={classes.changeLangLabel}>
        {translations?.changeLanguage}
      </Typography>
    </div>
  )

  const CustomDatePicker = props => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...props}
        animateYearScrolling
        format="dd MMM yyyy"
        variant="inline"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayOutlinedIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: { formControl: classes.inputLabel },
          shrink: false,
        }}
      />
    </MuiPickersUtilsProvider>
  )

  const DestinationBox = ({ myIndex, myCity }) => {
    const [placeNames, setPlaceNames] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [searchString, setSearchString] = useState("")
    // eslint-disable-next-line no-unused-vars
    const [cityResult, setCityResult] = useState()
    const [city, setCity] = useState(myCity)

    // const changeViewport = (city) => {
    //   console.log("City", city?.center[0], " - ", city?.center[1])
    //   setViewport({
    //     ...viewport,
    //     longitude: city?.center[0],
    //     latitude: city?.center[1],
    //     zoom: 10,
    //   })
    //   setMobileViewport({
    //     ...mobileViewport,
    //     longitude: city?.center[0],
    //     latitude: city?.center[1],
    //     zoom: 10,
    //   })
    // }

    return (
      <Autocomplete
        id="destination-box"
        autoComplete
        options={placeNames}
        value={city}
        getOptionLabel={option =>
          locale === "en" ? option.text_en : option.text_zh
        }
        onInputChange={(e, value) => {
          setSearchString(value)
          if (value !== "") {
            fetch(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=es,pt&language=${locale}&types=place&access_token=pk.eyJ1Ijoic29ieXlpYWwiLCJhIjoiY2s1ODhuNmt6MGt0bTNmbGp0ajdsd3FiNSJ9.q_W4-_adsd7Uw-47IqfK2g&autocomplete=true`
            )
              .then(response => response.json())
              .then(resultData => {
                setPlaceNames(resultData?.features)
                setCityResult(resultData?.features[0])
              })
          }
        }}
        onChange={(event, newValue, reason) => {
          if (reason === "select-option") {
            setSearchString("")
            setPlaceNames([])
            const newCities = [...cities]
            newCities[myIndex] = newValue
            setCity(newValue)
            setCities(newCities)
            // changeViewport(cityResult)
          }
          if (reason === "clear") {
            const newCities = [...cities]
            newCities.length > 1
              ? newCities.splice(myIndex, 1)
              : (newCities[0] = { place_name: "" })
            setRoute([])
            setCities(newCities)
          }
        }}
        onClose={() => {
          setSearchString("")
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={translations?.selectCity}
            margin="dense"
            InputLabelProps={{
              classes: { formControl: classes.inputLabel },
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment disableTypography position="start">
                  <Typography className={classes.destinationNo}>
                    {(myIndex + 1).toLocaleString("en-GB", {
                      minimumIntegerDigits: 2,
                    })}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
        popupIcon={<ExpandMoreIcon fontSize="small" />}
      />
    )
  }

  const citiesJson = {
    type: "FeatureCollection",
    features: cities,
  }
  const routesJson = {
    type: "FeatureCollection",
    features: route,
  }

  const circleStyle = {
    id: "point",
    type: "circle",
    paint: {
      "circle-radius": 5,
      "circle-color": "#FFF",
      "circle-stroke-width": 2,
    },
  }
  const routeStyle = {
    id: "line",
    type: "line",
    paint: {
      "line-color": "#FF9486",
      "line-width": 4,
    },
  }

  const updateRoutes = allCities => {
    const cityCoords = allCities.map(city => ({
      long: city?.geometry?.coordinates[0],
      lat: city?.geometry?.coordinates[1],
    }))

    let coordString = ""
    cityCoords.forEach(({ long, lat }, index) => {
      coordString = coordString + (index > 0 ? ";" : "") + long + "," + lat
    })

    fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${coordString}?geometries=geojson&access_token=pk.eyJ1IjoiYW1hYW4tbmFkZWVtIiwiYSI6ImNrYjFjeHdwOTAwb3cyeHJ1Y2RsanZlZDYifQ.9NWew-irxWlMid2W3oAtrw`
    )
      .then(response => response.json())
      .then(resultData => {
        setRoute(resultData.routes)
      })
  }

  return (
    <Layout isHeaderDisable pageContext={pageContext} location={location}>
      <SEO />
      <div
      // className={locale === "zh" && "chinese-version"}
      >
        <ThemeProvider theme={mapTheme}>
          <Hidden smDown>
            <Box display="flex">
              <Box
                display="flex"
                width={1 / 4}
                style={{ backgroundColor: "#faf5ef" }}
              >
                <Card className={classes.cardStyle}>
                  <CardContent style={{ height: "100%" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      height="100%"
                    >
                      <div>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mb={4}
                        >
                          <Button
                            component={LocalizedLink}
                            to="/"
                            disableRipple
                            className={classes.logo}
                          >
                            {translations?.zhutravel}
                          </Button>
                          <Typography className={classes.pgNumStyle}>
                            1&frasl;3
                          </Typography>
                        </Box>
                        <Typography
                          variant="overline"
                          style={{ fontFamily: "MabryProLight" }}
                        >
                          {translations?.customTour}
                        </Typography>
                        <Typography variant="h6">
                          {translations?.createCustomTour}
                        </Typography>
                        <Box mt={4}>
                          <FormLabel>{translations?.itinerary}</FormLabel>
                          <Box display="flex">
                            <Box
                              pr={1}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              alignSelf="center"
                            >
                              <div className={classes.destinationCircle} />
                              {/* eslint-disable-next-line array-callback-return */}
                              {cities.map((city, i) => {
                                if (i > 0)
                                  return (
                                    <>
                                      <Box mt={0.8} mb={0.8}>
                                        <div
                                          className={classes.destinationDot}
                                        />
                                        <Box mt={0.5} mb={0.5} clone>
                                          <div
                                            className={classes.destinationDot}
                                          />
                                        </Box>
                                        <div
                                          className={classes.destinationDot}
                                        />
                                      </Box>
                                      <div
                                        className={classes.destinationCircle}
                                      />
                                    </>
                                  )
                              })}
                            </Box>
                            <Box width="100%">
                              {cities.map((city, i) => (
                                <DestinationBox
                                  key={i}
                                  myCity={city}
                                  myIndex={i}
                                />
                              ))}
                            </Box>
                          </Box>
                        </Box>
                        <Button
                          color="secondary"
                          size="small"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => {
                            const newCities = [...cities]
                            newCities.push({ place_name: "" })
                            setCities(newCities)
                          }}
                          disabled={cities[cities.length - 1].place_name === ""}
                        >
                          {translations?.addCity}
                        </Button>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <CustomDatePicker
                              label={translations?.startDate}
                              value={startDate}
                              onChange={setStartDate}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomDatePicker
                              label={translations?.endDate}
                              value={endDate}
                              onChange={setEndDate}
                            />
                          </Grid>
                        </Grid>
                        <FormControl fullWidth>
                          <FormLabel>{translations?.noOfPeople}</FormLabel>
                          <Input
                            value={`${adults} ${translations?.adults}, ${children} ${translations?.children}, ${infants} ${translations?.infants}`}
                            onClick={event => handlePeopleClick(event)}
                            readOnly
                            inputProps={{ className: classes.noOfPeopleInput }}
                            endAdornment={
                              <InputAdornment position="end">
                                <PermIdentityIcon />
                              </InputAdornment>
                            }
                            className={classes.noOfPeopleInput}
                          />
                        </FormControl>
                        <Menu
                          id="people-menu"
                          anchorEl={anchorEl}
                          getContentAnchorEl={null}
                          keepMounted
                          open={peopleMenuOpen}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {noOfPeopleMenu.map(({ label, value, setter }) => (
                            <MenuItem key={label} button={false}>
                              <Box
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                              >
                                <IconButton
                                  disabled={value > 0 ? false : true}
                                  onClick={() =>
                                    handleModifyPeople(value - 1, setter)
                                  }
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                                <TextField
                                  value={value}
                                  label={label}
                                  InputLabelProps={{
                                    classes: {
                                      formControl: classes.peopleInputLabel,
                                    },
                                    shrink: false,
                                  }}
                                  InputProps={{
                                    classes: { input: classes.centerText },
                                    readOnly: true,
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                                <IconButton
                                  onClick={() =>
                                    handleModifyPeople(value + 1, setter)
                                  }
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Box>
                            </MenuItem>
                          ))}
                        </Menu>
                        <TextField
                          select
                          fullWidth
                          displayEmpty={false}
                          label={translations?.tourLanguage}
                          InputLabelProps={{
                            classes: { formControl: classes.inputLabel },
                            shrink: false,
                          }}
                          value={selectedLang}
                          onChange={event =>
                            setSelectedLang(event.target.value)
                          }
                          SelectProps={{
                            IconComponent: ChangeLangLabel,
                          }}
                        >
                          {tourLangs.map(lang => (
                            <MenuItem key={lang} value={lang}>
                              {lang}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          placeholder="500 EUR"
                          fullWidth
                          label={translations?.budgetPerPerson}
                          InputLabelProps={{
                            classes: { formControl: classes.inputLabel },
                            shrink: true,
                          }}
                          value={budget}
                          onChange={event => setBudget(event.target.value)}
                        />
                      </div>
                      <div>
                        <Divider />
                        <Box display="flex" justifyContent="end" mt={2}>
                          <Button
                            variant="contained"
                            to="/CreateCustom"
                            component={LocalizedLink}
                            color="secondary"
                            endIcon={
                              <div className={classes.arrow}>
                                <ArrowForwardIcon />
                              </div>
                            }
                            state={{
                              itineraryData: {
                                cities: cities,
                                startDate: startDate,
                                endDate: endDate,
                                selectedLang: selectedLang,
                                adults: adults,
                                childrenNum: children,
                                infants: infants,
                                budget: budget,
                              },
                              reqsData: reqsData,
                            }}
                          >
                            {translations?.getStarted}
                          </Button>
                        </Box>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <ReactMapGL
                {...viewport}
                mapboxApiAccessToken={
                  "pk.eyJ1IjoiYW1hYW4tbmFkZWVtIiwiYSI6ImNrYjFjeHdwOTAwb3cyeHJ1Y2RsanZlZDYifQ.9NWew-irxWlMid2W3oAtrw"
                }
                mapStyle={MAP_STYLE}
                ref={ref => {
                  mapRef.current = ref && ref.getMap()
                }}
                onViewportChange={nextViewport => {
                  _onViewportChange(nextViewport)
                }}
              >
                <Source id="route" type="geojson" data={routesJson}>
                  <Layer {...routeStyle} />
                </Source>
                <Source id="points" type="geojson" data={citiesJson}>
                  <Layer {...circleStyle} />
                </Source>
              </ReactMapGL>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              maxHeight="100vh"
            >
              <ReactMapGL
                {...mobileViewport}
                mapboxApiAccessToken={
                  "pk.eyJ1IjoiYW1hYW4tbmFkZWVtIiwiYSI6ImNrYjFjeHdwOTAwb3cyeHJ1Y2RsanZlZDYifQ.9NWew-irxWlMid2W3oAtrw"
                }
                mapStyle={MAP_STYLE}
                ref={ref => {
                  mapRef.current = ref && ref.getMap()
                }}
                onViewportChange={nextViewport => {
                  _onMobileViewportChange(nextViewport)
                }}
              >
                <Source id="route" type="geojson" data={routesJson}>
                  <Layer {...routeStyle} />
                </Source>
                <Source id="points" type="geojson" data={citiesJson}>
                  <Layer {...circleStyle} />
                </Source>
              </ReactMapGL>
              <Box height="55vh">
                <Box width="100%" maxWidth={600}>
                  <Card>
                    <CardContent>
                      <Box display="flex" flexDirection="column">
                        <div>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={4}
                          >
                            <Button
                              component={LocalizedLink}
                              to="/"
                              disableRipple
                              className={classes.logo}
                            >
                              {translations?.zhutravel}
                            </Button>
                            <Typography className={classes.pgNumStyle}>
                              1&frasl;3
                            </Typography>
                          </Box>
                          <Box mt={4}>
                            <FormLabel>{translations?.itinerary}</FormLabel>
                            <Box display="flex">
                              <Box
                                pr={1}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                alignSelf="center"
                              >
                                <div className={classes.destinationCircle} />
                                {/* eslint-disable-next-line array-callback-return */}
                                {cities.map((city, i) => {
                                  if (i > 0)
                                    return (
                                      <>
                                        <Box mt={0.8} mb={0.8}>
                                          <div
                                            className={classes.destinationDot}
                                          />
                                          <Box mt={0.5} mb={0.5} clone>
                                            <div
                                              className={classes.destinationDot}
                                            />
                                          </Box>
                                          <div
                                            className={classes.destinationDot}
                                          />
                                        </Box>
                                        <div
                                          className={classes.destinationCircle}
                                        />
                                      </>
                                    )
                                })}
                              </Box>
                              <Box width="100%">
                                {cities.map((city, i) => (
                                  <DestinationBox
                                    key={i}
                                    myCity={city}
                                    myIndex={i}
                                  />
                                ))}
                              </Box>
                            </Box>
                            <Button
                              color="secondary"
                              size="small"
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={() => {
                                const newCities = [...cities]
                                newCities.push({ place_name: "" })
                                setCities(newCities)
                              }}
                              disabled={
                                cities[cities.length - 1].place_name === ""
                              }
                            >
                              {translations?.addCity}
                            </Button>
                          </Box>
                          <TextField
                            select
                            fullWidth
                            displayEmpty={false}
                            label={translations?.tourLanguage}
                            InputLabelProps={{
                              classes: { formControl: classes.inputLabel },
                              shrink: false,
                            }}
                            value={selectedLang}
                            onChange={event =>
                              setSelectedLang(event.target.value)
                            }
                            SelectProps={{
                              IconComponent: ChangeLangLabel,
                            }}
                          >
                            {tourLangs.map(lang => (
                              <MenuItem key={lang} value={lang}>
                                {lang}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Box display="flex" justifyContent="space-between">
                            <Box width="48%">
                              <CustomDatePicker
                                label={translations?.startDate}
                                value={startDate}
                                onChange={setStartDate}
                              />
                            </Box>
                            <Box width="48%">
                              <CustomDatePicker
                                label={translations?.endDate}
                                value={endDate}
                                onChange={setEndDate}
                              />
                            </Box>
                          </Box>
                          <FormControl fullWidth>
                            <FormLabel>{translations?.noOfPeople}</FormLabel>
                            <Input
                              value={`${adults} ${translations?.adults}, ${children} ${translations?.children}, ${infants} ${translations?.infants}`}
                              onClick={event => handlePeopleClick(event)}
                              readOnly
                              inputProps={{
                                className: classes.noOfPeopleInput,
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <PermIdentityIcon />
                                </InputAdornment>
                              }
                              className={classes.noOfPeopleInput}
                            />
                          </FormControl>
                          <Menu
                            id="people-menu"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            keepMounted
                            open={peopleMenuOpen}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            {noOfPeopleMenu.map(({ label, value, setter }) => (
                              <MenuItem key={label} button={false}>
                                <Box
                                  display="flex"
                                  width="100%"
                                  justifyContent="space-between"
                                >
                                  <IconButton
                                    disabled={value > 0 ? false : true}
                                    onClick={() =>
                                      handleModifyPeople(value - 1, setter)
                                    }
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                  <TextField
                                    value={value}
                                    label={label}
                                    InputLabelProps={{
                                      classes: {
                                        formControl: classes.peopleInputLabel,
                                      },
                                      shrink: false,
                                    }}
                                    InputProps={{
                                      classes: { input: classes.centerText },
                                    }}
                                    onChange={event =>
                                      handleModifyPeople(
                                        event.target.value,
                                        setter
                                      )
                                    }
                                  />
                                  <IconButton
                                    onClick={() =>
                                      handleModifyPeople(value + 1, setter)
                                    }
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Box>
                              </MenuItem>
                            ))}
                          </Menu>
                          <TextField
                            placeholder="500 EUR"
                            fullWidth
                            label={translations?.budgetPerPerson}
                            InputLabelProps={{
                              classes: { formControl: classes.inputLabel },
                              shrink: true,
                            }}
                            value={budget}
                            onChange={event => setBudget(event.target.value)}
                          />
                        </div>
                        <div>
                          <Divider />
                          <Box display="flex" justifyContent="end" mt={2}>
                            <Button
                              variant="contained"
                              to="/CreateCustom"
                              component={LocalizedLink}
                              color="secondary"
                              endIcon={
                                <div className={classes.arrow}>
                                  <ArrowForwardIcon />
                                </div>
                              }
                              state={{
                                itineraryData: {
                                  cities: cities,
                                  startDate: startDate,
                                  endDate: endDate,
                                  selectedLang: selectedLang,
                                  adults: adults,
                                  childrenNum: children,
                                  infants: infants,
                                  budget: budget,
                                },
                                reqsData: reqsData,
                              }}
                            >
                              {translations?.getStarted}
                            </Button>
                          </Box>
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Hidden>
        </ThemeProvider>
      </div>
    </Layout>
  )
}

export default MapScreen
